<template>
  <router-link :to="to">
    <div class="row institutionalcard">
      <div class="col-12 col-sm-6 col-md-4">
        <img class="imgbs" :src="img" />
      </div>
      <div class="col-12 col-sm-6 col-md-8">
        <v-sheet class="dados">
          <v-card-title class="a headline secondary--text">{{
            title
          }}</v-card-title>
          <v-card-text class="b text-justify" v-html="textsubr"></v-card-text>
        </v-sheet>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  props: ["title", "text", "to", "img"],
  computed: {
    textsubr() {
      return this.text.substr(0, 680);
    },
  },
};
</script>
<style>
.institutionalcard img {
  max-width: 100%;
}
.institutionalcard .dados {
  text-align: center;
  display: inline-block;
}
.institutionalcard .dados .a {
  text-align: left;
}
.institutionalcard .dados .b {
  text-align: justify;
  font-weight: bold;
}
.imgbs {
  border-radius: 6px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
</style>