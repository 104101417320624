    
<template>
  <v-footer class="white dark px-0 py-0">
    <template>
      <div class="container">
        <div class="img footer fullfooter white lighten-4">
          <div class="">
            <img src="/img/logofooter.png" />
          </div>

          <div class="">
            <div class="redes center">
              <ul class="d-inline-block">
                <v-btn
                  class="my-2"
                  v-for="(s, i) in social"
                  :key="i"
                  :href="s.link"
                  :title="s.link"
                  icon
                  fab
                  small
                >
                  <v-icon class="dourado">{{ s.icon }}</v-icon>
                </v-btn>
              </ul>
            </div>
            <h6 class="center poppins py-4 font-weight-bold">
              Av. das Acácias, 1200 - Centro . SINOP - MT <br />Tel.:
              <a href="tel:556635315758">(66)3531-1209</a> / Whatsapp:
              <a
                target="_blank"
                href="https://api.whatsapp.com/send?phone=5566999654263&text=Ol%C3%A1!%20Acessei%20o%20site%20da%20Despachante%20Real%20e%20queria%20saber%20mais%20sobre%20seus%20servi%C3%A7os."
                >(66) 9 9965-4263</a
              >
            </h6>
          </div>
          <div class="abnt">
            <img src="/img/abnt.png" height="130" />
          </div>
        </div>
      </div>
    </template>
    <div class="bt4 px-0 blue-grey darken-4 justify-space-around">
      <div class="white--text a text-center py-2 font-weight-bold">
        {{ name }} © 2021. Todos os direitos reservados.
      </div>
      <a href="https://www.mrxweb.com.br/" target="_blank"
        ><img src="/img/mrx.png" alt="MRX Web Sites" title="MRX Web Sites"
      /></a>
    </div>
    <div class="btnwhafw">
      <whatsapp-button
        :tel="'5566999654263'"
        :text="'Acessei ao seu site e gostaria de saber mais sobre seus serviços.'"
      ></whatsapp-button>
    </div>
  </v-footer>
</template>

<script>
import WhatsappButton from "../components/WhatsappButton";

export default {
  data: () => ({
    logo: "/img/favicon-white.svg",
  }),
  computed: {
    name() {
      return this.$store.state.holder.name;
    },
    social() {
      return this.$store.state.holder.social;
    },
  },
  components: {
    WhatsappButton,
  },
};
</script>

<style scoped>
@media (max-width: 767px) {
  .bt4 {
    display: inline-block !important;
    text-align: center;
  }
  .fullfooter {
    display: inline-block !important;
  }
}
.bt4 {
  border-top: 5px solid #e1b768 !important;
  display: flex;
  padding: 12px 0;
  width: 100%;
}
.fullfooter {
  width: 100%;
  text-align: center;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}
.logofooter {
  position: absolute;
  width: 100%;
}
.fullfooter h5 {
  display: inline-block;
  margin-top: 10px;
}
.abnt img {
  margin-top: 10px;
}
.btnwhafw {
  display: none;
}
@media (max-width: 960px) {
  .btnwhafw {
    display: block;
  }
}
</style>