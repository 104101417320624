<template>
  <v-btn
    :class="[!sm ? 'rounded-xl' : '']"
    :href="`https://wa.me/${tel}?text=${text}`"
    :fab="sm"
    :large="!sm"
    target="_blank"
    color="#00E676"
    dark
    fixed
    bottom
    right
  >
    <v-icon :left="!sm" :size="!sm ? 24 : ''">fab fa-whatsapp</v-icon>
    <span v-if="!sm" class="whatsapp-button-text">WhatsApp</span>
  </v-btn>
</template>

<script>
export default {
  props: {
    tel: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
  },
  computed: {
    sm() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
};
</script>

<style>
.whatsapp-button-text {
  font-family: Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 17px;
  text-transform: none;
}
</style>
