<template>
  <div class="grey lighten-5 outlined">
    <div v-if="$vuetify.breakpoint.mdAndUp" fluid>
      <v-container class="py-2 d-flex justify-end align-center">
        <h6 class="mr-4">
          Av. das Acácias, 1200 - Centro . Sinop - MT / (66) 3531-1209
        </h6>
        <v-btn
          v-for="(s, i) in social"
          :key="i"
          :href="s.link"
          :title="s.link"
          icon
          fab
          x-small
        >
          <v-icon class="primary--text">{{ s.icon }}</v-icon>
        </v-btn>
      </v-container>
      <div class="grey lighten-4 bgmenu elevation-1">
        <v-container class="d-flex justify-content-between py-0">
          <router-link class="my-n10 d-flex align-center" to="/">
            <img class="logo" src="/img/logo.png" />
            <img class="logoabnt" src="/img/abntmini.png" />
          </router-link>
          <nav class="ml-auto py-3 menudown">
            <template v-for="(route, i) in menu">
              <v-btn
                v-if="!route.dropdown"
                :key="i"
                :to="route.path"
                class="text-uppercase font-weight-bold mr-2 poppins"
                text
                >{{ route.name }}</v-btn
              >
              <v-menu v-else :key="i" open-on-hover bottom offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text>{{ route.name }}</v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-for="(r, i) in route.dropdown"
                    :key="i"
                    :to="r.path"
                  >
                    <v-list-item-title>{{ r.name }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </nav>

          <a
            href="https://api.whatsapp.com/send?1=pt_BR&phone=5566999654263"
            target="_blank"
          >
            <div class="block3">
              <div class="whatsapp-header py-2 px-4 d-flex" v-ripple>
                <i class="fa fa-whatsapp"></i>
                <div
                  class="d-flex flex-column justify-center text-body-2 font-weight-bold white--text"
                >
                  <span> Fale Conoso </span>
                  <span> WHATSAPP </span>
                </div>
              </div>
            </div>
          </a>
        </v-container>
      </div>
    </div>
    <template v-else>
      <v-app-bar fixed class="grey lighten-4" app>
        <router-link class="h-100 white--text" to="/">
          <img class="logo" src="/img/logo.png" contain height="100%" />
        </router-link>

        <v-spacer></v-spacer>

        <v-app-bar-nav-icon
          color="primary"
          @click="drawer = !drawer"
        ></v-app-bar-nav-icon>
      </v-app-bar>

      <v-navigation-drawer v-model="drawer" fixed temporary max-width="300">
        <v-subheader>MENU</v-subheader>
        <v-list-item-group v-model="current">
          <v-list v-for="(route, i) in menu" :key="i">
            <v-list-item v-if="!route.dropdown" :to="route.path">
              <v-list-item-icon>
                <v-icon>{{ route.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-title>{{ route.name }}</v-list-item-title>
            </v-list-item>

            <v-list-group v-else>
              <template v-slot:activator>
                <v-list-item-icon>
                  <v-icon>{{ route.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-title>{{ route.name }}</v-list-item-title>
              </template>

              <v-list v-for="(subroute, j) in route.dropdown" :key="j">
                <v-list-item :to="subroute.path">
                  <v-list-item-icon>
                    <v-icon>{{ subroute.icon }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-title>{{ subroute.name }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-list-group>
          </v-list>
        </v-list-item-group>
      </v-navigation-drawer>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      drawer: false,
      menu: [
        { path: "/", name: "Início", icon: "fas fa-home" },
        {
          dropdown: [
            { path: "/quem-somos", name: "Quem Somos" },
            {
              path: "/politica-e-objetivos-de-qualidade",
              name: "Politica e Objetivos de Qualidade",
            },
          ],
          name: "A Empresa",
          icon: "fas fa-industry",
        },
        { path: "/contato", name: "Contato", icon: "fas fa-envelope" },
      ],
      current: this.$route.path,
    };
  },
  computed: {
    social() {
      return this.$store.state.holder.social;
    },
    files() {
      return this.$store.state.files + "/";
    },
  },
  methods: {
    close() {
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("code");
      window.location.href = "/area-restrita";
    },
  },
};
</script>

<style scoped>
.menudown button {
  font-weight: bold;
}
.bgmenu {
  position: relative;
  z-index: 99;
}
.logoabnt {
  margin: 0 15px;
}
.relative {
  position: relative;
}
.center {
  text-align: center;
}
.right {
  text-align: right;
}

.whatsapp-header {
  background: #85b200;
}
.whatsapp-header i {
  padding: 7px 10px;
  font-size: 2em;
  background: #fff;
  color: #85b200;
  border-radius: 5px;
  margin-right: 8px;
}

.posabs {
  height: 70px;
}
.v-btn__content {
  display: inline-block !important;
}
.v-btn:not(.v-btn--round).v-size--large {
  height: 70px !important;
}
.posabs > button {
  height: 70px !important;
  font-size: 15px !important;
  width: 100%;
  background-color: #eeeeee !important;
  color: #a7a7aa !important;
}
.posabs > button:hover {
  color: #fff !important;
  background-color: #f00 !important;
}
.positionabs2 {
  margin-top: 50px;
}
.menulogin {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
}
.right h5 {
  float: right;
  margin: 12px 8px;
}
.right ul {
  float: right;
}
@media (max-width: 1050px) {
  .whatsapp-header span {
    display: none;
  }
}
</style>
