<template>
  <div class="linha-center">
    <v-btn class="btnopen" color="primary" @click="open">Ver menu</v-btn>
    <div class="positionabs2" id="openclick">
      <v-container>
        <div class="row">
          <div class="col-12 col-sm-12">
            <div class="posabs">
              <v-menu open-on-hover bottom offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn large v-bind="attrs" v-on="on">
                    <img src="../../public/img/icone1.png" /> TERRESTRES
                    <i class="fa fa-chevron-up" aria-hidden="true"></i>
                  </v-btn>
                </template>
                <v-list class="bgred">
                  <v-list-item
                    v-for="(itema, index) in terrestre"
                    :key="index"
                    :to="'/servicos/' + itema.link"
                  >
                    <v-list-item-title class="branco">{{
                      itema.title
                    }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
          <div class="col-12 col-sm-12">
            <div class="posabs">
              <v-menu open-on-hover bottom offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn large v-bind="attrs" v-on="on">
                    <img src="../../public/img/icone2.png" /> MARÍTIMOS
                    <i class="fa fa-chevron-up" aria-hidden="true"></i>
                  </v-btn>
                </template>
                <v-list class="bgred">
                  <v-list-item
                    v-for="(itemb, index) in maritimo"
                    :key="index"
                    :to="'/servicos/' + itemb.link"
                  >
                    <v-list-item-title class="branco">{{
                      itemb.title
                    }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
          <div class="col-12 col-sm-12">
            <div class="posabs">
              <v-menu open-on-hover bottom offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn large v-bind="attrs" v-on="on">
                    <img src="../../public/img/icone3.png" /> IMOBILIÁRIO
                    <i class="fa fa-chevron-up" aria-hidden="true"></i>
                  </v-btn>
                </template>
                <v-list class="bgred">
                  <v-list-item
                    v-for="(itemc, index) in imobiliario"
                    :key="index"
                    :to="'/servicos/' + itemc.link"
                  >
                    <v-list-item-title class="branco">{{
                      itemc.title
                    }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
          <div class="col-12 col-sm-12">
            <div class="posabs">
              <v-menu open-on-hover bottom offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn large v-bind="attrs" v-on="on">
                    <img src="../../public/img/icone4.png" /> Armas/Bélico
                    <i class="fa fa-chevron-up" aria-hidden="true"></i>
                  </v-btn>
                </template>
                <v-list class="bgred">
                  <v-list-item
                    v-for="(itemc, index) in belico"
                    :to="'/servicos/' + itemc.link"
                    :key="index"
                  >
                    <v-list-item-title class="branco">{{
                      itemc.title
                    }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({}),
  methods: {
    open() {
      document.getElementById("openclick").style.display = "block";
    },
  },
  computed: {
    terrestre() {
      let menut = this.$rest("menuterrestre").list;
      return menut && menut.slice(0, 15);
    },
    maritimo() {
      let menumar = this.$rest("menumaritimo").list;
      return menumar && menumar.slice(0, 15);
    },
    imobiliario() {
      let menuimo = this.$rest("menuimobiliario").list;
      return menuimo && menuimo.slice(0, 15);
    },
    belico() {
      let menuprev = this.$rest("menuarmas").list;
      return menuprev && menuprev.slice(0, 15);
    },
  },
  beforeCreate() {
    this.$rest("menuterrestre").get();
    this.$rest("menumaritimo").get();
    this.$rest("menuimobiliario").get();
    this.$rest("menuarmas").get();
  },
};
</script>
<style>
.btnopen {
  display: none;
}
.v-menu__content {
  z-index: 99999999 !important;
}
.linha-center {
  display: inline-block;
  width: 100%;
  position: relative;
}
.positionabs2 {
  position: relative;
  top: 0;
  display: block;
}
.bgred {
  background-color: #f00 !important;
}
.bgred > div:hover {
  background: #00000088;
}

@media (max-width: 700px) {
  .btnopen {
    display: block;
  }
  .posabs {
    position: relative;
    top: 0;
  }
  .positionabs2 {
    display: none;
  }
}
.posabs img {
  float: left;
  margin-right: 14px;
}
.posabs i {
  float: right;
  margin-left: 14px;
}
.divnav > img,
.divnav > div,
.divnav > i {
  float: left;
  margin: 0 4px;
}
.divnav > div {
  width: 180px;
}
.posabs button:hover img {
  filter: brightness(0) invert(1);
}
.tdn {
  text-decoration: none;
}
.h70 {
  height: 70px !important;
  width: 100%;
}
</style>
