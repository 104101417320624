<template>
  <div class="home">
    <v-carousel
      class="slideprincipal relative"
      cycle
      delimiter-icon="fas fa-minus"
      hide-delimiters
      show-arrows-on-hover
    >
      <template v-if="!slides.length">
        <v-carousel-item>
          <img class="carousel-img" src="/img/banners.jpg" />
        </v-carousel-item>
      </template>
      <template v-else>
        <v-carousel-item v-for="(s, i) in slides" :key="i">
          <img class="carousel-img" :src="files + s.img" />
        </v-carousel-item>
      </template>
    </v-carousel>

    <div class="linha-center">
      <div class="positionabs">
        <v-container>
          <div class="row">
            <div class="col-12 col-sm-6 col-md-6 col-lg-3">
              <div class="posabs">
                <v-menu open-on-hover top offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn large v-bind="attrs" v-on="on" class="btnapple">
                      <img src="img/icone1.png" />
                      <span>Terrestres</span>
                      <i class="fa fa-chevron-up" aria-hidden="true"></i>
                    </v-btn>
                  </template>
                  <v-list class="bgred">
                    <v-list-item
                      v-for="(itema, index) in terrestre"
                      :key="index"
                      :to="'/servicos/' + itema.link"
                    >
                      <v-list-item-title class="branco">
                        {{ itema.title }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-3">
              <div class="posabs">
                <v-menu open-on-hover top offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn large v-bind="attrs" v-on="on" class="btnapple">
                      <img src="img/icone2.png" /> <span>Marítimos</span>
                      <i class="fa fa-chevron-up" aria-hidden="true"></i>
                    </v-btn>
                  </template>
                  <v-list class="bgred">
                    <v-list-item
                      v-for="(itemb, index) in maritimo"
                      :key="index"
                      :to="'/servicos/' + itemb.link"
                    >
                      <v-list-item-title class="branco">
                        {{ itemb.title }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </div>

            <div class="col-12 col-sm-6 col-md-6 col-lg-3">
              <div class="posabs">
                <v-menu open-on-hover top offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn large v-bind="attrs" v-on="on" class="btnapple">
                      <img src="img/icone3.png" /> <span>Imobiliário</span>
                      <i class="fa fa-chevron-up" aria-hidden="true"></i>
                    </v-btn>
                  </template>
                  <v-list class="bgred">
                    <v-list-item
                      v-for="(itemc, index) in imobiliario"
                      :key="index"
                      :to="'/servicos/' + itemc.link"
                    >
                      <v-list-item-title class="branco">
                        {{ itemc.title }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </div>

            <div class="col-12 col-sm-6 col-md-6 col-lg-3">
              <div class="posabs">
                <v-menu open-on-hover top offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn large v-bind="attrs" v-on="on" class="btnapple">
                      <img src="img/icone4.png" /> <span>Armas/Bélico</span>
                      <i class="fa fa-chevron-up" aria-hidden="true"></i>
                    </v-btn>
                  </template>
                  <v-list class="bgred">
                    <v-list-item
                      v-for="(itemd, index) in belico"
                      :key="index"
                      :to="'/servicos/' + itemd.link"
                    >
                      <v-list-item-title class="branco">
                        {{ itemd.title }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </div>
          </div>
        </v-container>
      </div>
    </div>

    <v-container>
      <section class="aboutdiv">
        <template v-if="!about.text">
          <v-skeleton-loader
            class="my-10 mx-2"
            type="image"
            min-width="330px"
            min-height="200px"
          ></v-skeleton-loader>
          <div>
            <v-skeleton-loader class="mb-4" type="header"></v-skeleton-loader>
            <v-skeleton-loader class="mb-4" type="chip"></v-skeleton-loader>
            <v-skeleton-loader
              class="mb-4"
              type="paragraph"
            ></v-skeleton-loader>
            <v-skeleton-loader
              class="mb-4"
              type="paragraph"
            ></v-skeleton-loader>
          </div>
        </template>
        <template v-else>
          <institutional-card
            :to="'/quem-somos'"
            :img="files + about.img"
            :title="about.title"
            :text="about.text"
          ></institutional-card>
        </template>
      </section>
    </v-container>

    <div class="bannerfull center">
      <router-link :to="'/servicos'">
        <h3>ÁREAS DE ATUAÇÃO</h3>
        <div class="maxw1">
          <img src="/img/auto-escola-capa.jpg" />
        </div>
        <div class="maxw2">
          <img src="/img/imgbg2.jpg" />
        </div>
      </router-link>
    </div>

    <div class="title center">
      <h3>SERVIÇOS MAIS PROCURADOS</h3>
    </div>
    <v-container>
      <template>
        <v-sheet class="mx-auto pt-6">
          <div class="grid5">
            <photos
              v-for="(service, n) in services"
              :key="n"
              :to="'/servicos/' + service.id"
              :files="files"
              :img="service.img"
              :title="service.title"
            ></photos>
          </div>
        </v-sheet>
      </template>
    </v-container>

    <iframe
      class="map"
      :src="map"
      allowfullscreen
      aria-hidden="false"
      tabindex="0"
    ></iframe>
  </div>
</template>

<script>
// import carousel from "vue-owl-carousel";
import InstitutionalCard from "../components/InstitutionalCard";
import Photos from "../components/Photos";

export default {
  data: () => ({
    nutrologyDialog: false,
    video: null,
  }),
  computed: {
    server() {
      return this.$store.state.server;
    },
    files() {
      return this.$store.state.files + "/";
    },
    about() {
      return this.$rest("about").item;
    },
    slides() {
      let slides = this.$rest("slides").list;
      return slides && slides.slice(0, 10);
    },
    services() {
      let services = this.$rest("services").list;
      return services && services.slice(0, 10);
    },
    map() {
      return this.$store.state.holder.map;
    },
    terrestre() {
      let menut = this.$rest("menuterrestre").list;
      return menut && menut.slice(0, 15);
    },
    maritimo() {
      let menumar = this.$rest("menumaritimo").list;
      return menumar && menumar.slice(0, 15);
    },
    imobiliario() {
      let menuimo = this.$rest("menuimobiliario").list;
      return menuimo && menuimo.slice(0, 15);
    },
    belico() {
      let menuprev = this.$rest("menuarmas").list;
      return menuprev && menuprev.slice(0, 15);
    },
  },
  beforeCreate() {
    this.$rest("slides").get();
    this.$rest("about").get(1);
    this.$rest("services").get();
    this.$rest("menuterrestre").get();
    this.$rest("menumaritimo").get();
    this.$rest("menuimobiliario").get();
    this.$rest("menuarmas").get();
  },
  components: {
    // carousel,
    InstitutionalCard,
    Photos,
  },
};
</script>

<style>
.texcinza {
  color: #a7a7aa;
}
.map {
  width: 100%;
  height: 400px;
  margin-bottom: -6px;
  border: none;
  border-top: 12px solid #df0209 !important;
  border-bottom: 12px solid #df0209 !important;
  margin-top: 30px;
}
.bannerfull {
  display: inline-block;
  width: 100%;
  position: relative;
  background: #ffffff;
  margin: 30px 0;
}
.bannerfull h3 {
  position: absolute;
  width: 100%;
  top: 25px;
  left: 0;
  z-index: 99999;
  color: #fff;
}
.title h3 {
  font-size: 1.4em;
  font-weight: bold;
  margin: 20px 0;
}
.home-logo-xs {
  top: 50px;
  z-index: 4;
  width: 100%;
  padding: 70px;
  filter: drop-shadow(1px 1px 1px #00000088);
}

.carousel-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.videos {
  display: grid;
  grid-template-columns: 12fr 13fr 12fr;
  gap: 20px;
  grid-auto-rows: max-content;
  justify-content: space-between;
  align-items: center;
}

.photodiv {
  display: inline-block;
}

.news {
  width: 100%;
  padding: 10px;
}

.logo_footer {
  max-width: 100%;
}

.branco {
  color: #fff;
}

.maxw1 img {
  max-width: 100%;
}
.maxw2 {
  display: none;
}
@media screen and (max-width: 600px) {
  .maxw1 {
    display: none;
  }
  .maxw2 {
    display: block;
  }
  .maxw2 img {
    max-width: 100%;
  }
  .article {
    height: auto !important;
  }
  .article .v-carousel__item {
    height: auto !important;
  }
  .slideprincipal {
    height: 180px !important;
  }
  .slideprincipal .v-carousel__item {
    height: 180px !important;
  }
}

.slideprincipal,
.v-carousel__item,
.carousel-img {
  height: auto !important;
}
.linha-center {
  display: inline-block;
  width: 100%;
  position: relative;
}
.positionabs {
  position: relative;
  top: -60px;
}
.bgred {
  background-color: #df0209 !important;
}
.bgred > div:hover {
  background: #00000088;
}
.posabs > button {
  height: 70px !important;
  font-size: 15px !important;
  width: 100%;
  background-color: #eeeeee !important;
  color: #a7a7aa !important;
}
.posabs > button:hover {
  color: #fff !important;
  background-color: #df0209 !important;
}

@media (max-width: 700px) {
  .posabs {
    position: relative;
    top: 0;
  }
}
.posabs img {
  float: left;
  margin-right: 14px;
}
.posabs i {
  float: right;
  margin-left: 14px;
}
.divnav > img,
.divnav > div,
.divnav > i {
  float: left;
  margin: 0 4px;
}
.divnav > div {
  width: 180px;
}
.posabs button:hover img {
  filter: brightness(0) invert(1);
}
.tdn {
  text-decoration: none;
}
.h70 {
  height: 70px !important;
  width: 100%;
}
.grid5 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
}

@media (max-width: 500px) {
  .grid5 {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 501px) and (max-width: 1100px) {
  .grid5 {
    grid-template-columns: repeat(3, 1fr);
  }
}
.btnapple img {
  width: 65px;
  height: 65px;
  float: left;
}
.btnapple span {
  width: 180px;
  float: left;
}
.btnapple i {
  width: 15px;
  float: left;
}
</style>
