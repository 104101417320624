import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Contact from "../views/Contact.vue";
import About from "../views/About.vue";
import Objectqual from "../views/Objectqual.vue";
import Services from "../views/Services.vue";
import Service from "../views/Service.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Real Despachante",
    component: Home,
  },
  {
    path: "/contato",
    name: "Contato - Real Despachante",
    component: Contact,
  },
  {
    path: "/quem-somos",
    name: "Quem Somos - Real Despachante",
    component: About,
  },

  {
    path: "/politica-e-objetivos-de-qualidade",
    name: "Política e Objetivos de qualidade - Real Despachante",
    component: Objectqual,
  },

  {
    path: "/servicos",
    name: "Matérias - Real Despachante",
    component: Services,
  },
  {
    path: "/servicos/:curso",
    name: "Matérias - Real Despachante",
    component: Service,
  },

];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.afterEach((to) => {
  document.title = to.name;
  window.scrollTo(0, 0);
});

export default router;
