<template>
  <v-container>
    <h2 align="center">Serviços</h2>
    <template v-if="!curso">
      <v-skeleton-loader class="mb-4" type="heading"></v-skeleton-loader>
      <v-skeleton-loader class="mb-4" type="chip"></v-skeleton-loader>
      <v-skeleton-loader class="mb-4" type="image"></v-skeleton-loader>
      <v-skeleton-loader class="mb-4" type="paragraph"></v-skeleton-loader>
      <v-skeleton-loader type="paragraph"></v-skeleton-loader>
    </template>
    <div class="curso-post" v v-else>
      <v-row>
        <v-col cols="12" sm="12" md="4">
          <nav-submenu></nav-submenu>
        </v-col>
        <v-col cols="12" md="8" sm="12">
          <h4>{{ curso.title }}</h4>
          <img
            v-if="curso.img"
            class="elevation-2 my-4 mw100"
            :src="files + curso.img"
            :alt="curso.title"
          />
          <div v-html="curso.text"></div>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import NavSubmenu from "../templates/NavSubmenu";

export default {
  data: () => ({}),
  computed: {
    files() {
      return this.$store.state.files + "/";
    },
    id() {
      return parseInt(this.$route.params.curso);
    },
    curso() {
      return this.$rest("cursos").item;
    },
  },
  created() {
    this.$rest("cursos").get(this.id);
  },
  watch: {
    id() {
      this.$rest("cursos").get(this.id);
    },
  },
  components: {
    NavSubmenu,
  },
};
</script>

<style>
.article-post {
  display: grid;
  grid-template:
    "title title title"
    "time  time  ."
    ".     img   ."
    "text  text  text"/ 1fr auto 1fr;
  align-content: start;
}
.article-post > h4 {
  grid-area: title;
}
.article-post > time {
  grid-area: time;
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
}
.article-post > img {
  grid-area: img;
  border-radius: 4px;
  max-height: 400px;
  max-width: 100%;
}
.article-post > div {
  grid-area: text;
  word-break: break-word;
}
.article .article-list {
  display: grid;
  grid-auto-flow: row;
  gap: 10px;
}
.mw100 {
  max-width: 100%;
}
</style>