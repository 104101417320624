import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const server = "https://www.realdespachante.com.br";

const holder = {
  namespaced: true,
  state: {
    name: "Real Despachante",
    social: [
      {
        link: "https://goo.gl/maps/ohRBRsWEu282kx1k6",
        title:
          "Escritório Operacional: Av. das Acácias, 1200 - Centro - Sinop - MT",
        icon: "fas fa-map-marker-alt",
      },
      {
        link: "mailto:valdemir@realdespachante.com.br",
        title: "valdemir@realdespachante.com.br",
        icon: "fas fa-envelope",
      },
      {
        link: "https://www.facebook.com/despachantereal/",
        title: "Facebook @realdespachante",
        icon: "fab fa-facebook-f",
      },
      // {
      //   link: 'https://www.linkedin.com/company/autoescolareal/',
      //   title: 'company/autoescolareal',
      //   icon: 'fab fa-linkedin'
      // },
      // {
      //   link: '',
      //   title: 'Youtube',
      //   icon: 'fab fa-youtube'
      // },
      {
        link: "https://www.instagram.com/realdespachante/",
        title: "Instagram @realdespachante",
        icon: "fab fa-instagram",
      },
      {
        link: "tel:+556635311209",
        title: "(66)3531-1209",
        icon: "fas fa-mobile-alt",
      },
      // {
      //   link: 'tel:+556635320044',
      //   title: '(66) 3532-0044',
      //   icon: 'fas fa-phone'
      // }
    ],
    map:
      "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15618.597845604423!2d-55.5069435!3d-11.8597923!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xaccbccdd906864e3!2sAutoescola%20e%20Despachante%20Real!5e0!3m2!1sen!2sbr!4v1596742293110!5m2!1sen!2sbr",
  },
};

export default new Vuex.Store({
  state: {
    files: server,
    server,
  },
  modules: {
    holder,
  },
});
