<template>
  <div class="contact">
    <v-container class="my-10">
      <h2 class="center">Contato</h2>
      <div class="d-flex flex-column flex-md-row justify-center">
        <registration-form class="mb-4 mb-md-0" :action="server + '/sendEmail'"></registration-form>
        <ul class="d-flex flex-column">
          <li class="mb-2" v-for="(s, i) in social" :key="i">
            <v-btn color="primary elevation-0" fab x-small left :href="s.link" target="_blank">
              <v-icon>{{ s.icon }}</v-icon>
            </v-btn>
            <span class="ml-2">{{ s.title }}</span>
          </li>
        </ul>
      </div>
    </v-container>
    <iframe
      class="map"
      :src="map"
      width="100%"
      height="400"
      frameborder="0"
      allowfullscreen
      aria-hidden="false"
      tabindex="0"
    ></iframe>
  </div>
</template>

<script>
import RegistrationForm from "../components/RegistrationForm";

export default {
  computed: {
    server() {
      return this.$store.state.server;
    },
    social() {
      return this.$store.state.holder.social;
    },
    map() {
      return this.$store.state.holder.map;
    }
  },
  components: {
    RegistrationForm
  }
};
</script>

<style>
.contacts ul {
  padding-left: 0 !important;
  list-style: none;
}
.map {
  margin-bottom: -6px;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}
</style>