<template>
  <v-container class="template-institutional">
    <v-container class="grey lighten-5">
      <v-row>
        <v-col cols="12" sm="12" md="4">
          <nav-submenu></nav-submenu>
        </v-col>
        <v-col cols="12" md="8" sm="12">
          <template v-if="!text">
            <v-skeleton-loader class="my-10 mx-2" type="image" min-width="330px" min-height="200px"></v-skeleton-loader>
            <div>
              <v-skeleton-loader class="mb-4" type="header"></v-skeleton-loader>
              <v-skeleton-loader class="mb-4" type="chip"></v-skeleton-loader>
              <v-skeleton-loader class="mb-4" type="paragraph"></v-skeleton-loader>
              <v-skeleton-loader class="mb-4" type="paragraph"></v-skeleton-loader>
            </div>
          </template>
          <template v-else>
            <div class="center" v-if="imgverif">
              <img :src="img" />
            </div>
            <v-sheet>
              <v-card-title class="headline secondary--text">{{ title }}</v-card-title>
              <v-card-text class="text-justify" v-html="text"></v-card-text>
            </v-sheet>
          </template>
        </v-col>
      </v-row>
    </v-container>
    <template></template>
  </v-container>
</template>


<script>
import NavSubmenu from "../templates/NavSubmenu";

export default {
  props: {
    img: String,
    title: String,
    imgverif: String,
    text: String
  },
  components: {
    NavSubmenu
  }
};
</script>
<style>
.template-institutional {
  margin-bottom: 35px;
}
.template-institutional img {
  display: inline-block;
  max-width: 100%;
}
.template-institutional > div {
  display: inline-block;
  width: 100%;
}
</style>